/**
 * @prettier
 */

import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import './assets/styles/common.scss';
import Alerts from './components/Alerts';
import PopoverNeedChangeAddress from './components/PopoverNeedChangeAddress';
import { REQUEST_ETA_INTERVAL, ANALYTICS_PRODUCTS_SHOWN_INTERVAL } from './config';
import DeliveryAddress from './pages/DeliveryAddress/DeliveryAddress';
import Inject from './pages/Inject';
import PageLayout from './pages/PageLayout';
import { listenerOnResize } from './UI';
import PopoverGeneralError from './components/PopoverGeneralError';
import { mainStore } from './stores/MainStore';
import { userStore } from './stores/UserStore';
import { orderStore } from './stores/OrderStore';
import { SubscriptionFlag } from './api/Customer';
import PopoverFuckup from './components/PopoverFuckup';
import { catalogStore } from './stores/CatalogStore';
import { storiesStore } from './stores/StoriesStore';
import { checkoutStore } from './stores/CheckoutStore';
import { useFCWidget } from './hooks/useFCWidget';
import { ETADeliveryMethodType } from './api/ETA';
import { company } from './company/Company';
import { FLAG_HIDE_LANGS } from './flags';

export default observer(() => {
  const history = useHistory();
  const { search } = useLocation();
  const [isAvailable, setIsAvailable] = useState(false);
  const isAvailableBrowser = (): boolean => {
    // todo: rewrite to !Promise.prototype.finally
    if (window.navigator.userAgent.indexOf('Chrome') === -1) {
      return true;
    }
    const ver = window.navigator.userAgent.replace(/(.*)(Chrome\/[\d.]*)(.*)/, '$2');
    if (ver === window.navigator.userAgent) {
      return true;
    }
    if (parseInt(ver.split('/')[1], 10) >= 63) {
      return true;
    }
    if (window.ReactNativeWebView) {
      mainStore.sendToRN('mount');
    }
    return false;
  };
  const checkPushPermission = () => {
    if (!mainStore.isBlockedPush) {
      return;
    }
    try {
      mainStore.sendToRN('getPermission', {}, (e) => {
        if (!e.permission) {
          return;
        }
        const isEmailSubscribe =
          userStore.personalData.subscriptionFlags['personalised']?.email ||
          false ||
          userStore.personalData.subscriptionFlags['discounts']?.email ||
          false ||
          userStore.personalData.subscriptionFlags['third_party']?.email ||
          false;
        mainStore.sendToRN(
          'subscribeToNotifications',
          {
            email: userStore.personalData.email?.trim() || '',
            isPush: true,
            isEmail: isEmailSubscribe,
          },
          () => {
            mainStore.setIsBlockedPush(false);
            const flags: SubscriptionFlag[] = [
              {
                name: 'personalised',
                push: true,
                email: userStore.personalData.subscriptionFlags['personalised']?.email || false,
              },
              {
                name: 'discounts',
                push: true,
                email: userStore.personalData.subscriptionFlags['discounts']?.email || false,
              },
              {
                name: 'third_party',
                push: true,
                email: userStore.personalData.subscriptionFlags['third_party']?.email || false,
              },
            ];
            userStore
              .updateDeviceData(
                userStore.fullName,
                userStore.personalData.email?.trim() || '',
                flags,
              )
              .catch((error) => error && console.error(error));
            userStore
              .updateSubscription(flags, 'Notification settings')
              .catch((error) => error && console.error(error));
          },
        );
      });
    } catch (error) {
      error && console.error(error);
    }
  };
  useFCWidget();

  useEffect(() => {
    if (catalogStore.cart.length > 0 && !mainStore.isShowOutStockPopover) {
      catalogStore.setCalculationProcess({
        requestId: catalogStore.calculationProcess.requestId + 1,
        isError: false,
        isLoading: true,
      });
      catalogStore.debouncedCalculateCart();
    }
  }, [
    JSON.stringify([catalogStore.cart, catalogStore.promocode, checkoutStore.deliveryMethod]),
    mainStore.isShowOutStockPopover,
    checkoutStore.useBonuses,
    orderStore.etaCalculation?.warehouse?.code,
  ]);

  useLayoutEffect(() => {
    setIsAvailable(isAvailableBrowser());
  }, []);

  useLayoutEffect(() => {
    if (search.includes('explain-service-fee')) {
      mainStore.setIsShowServiceFeePopover(true);
    }
  }, [search]);

  useLayoutEffect(() => {
    if (!isAvailable || !mainStore.isAllStoresSynchronized) {
      return;
    }
    if (!userStore.deliveryAddress?.coordinates) {
      userStore.setDeliveryAddress(null);
    }
    listenerOnResize();

    orderStore.requestCurrency();
    if (userStore.isAuthorized) {
      userStore.requestPersonalData().catch((error) => error && console.error(error));
      userStore.requestBonuses().catch((error) => error && console.error(error));
    } else {
      userStore.resetCustomerType();
    }
    mainStore.setSentryUser({
      id: userStore.personalData.id || '',
      name: userStore.fullName,
      email: userStore.personalData.email || '',
      phone: userStore.personalData.phone || '',
    });
    if (!window.ReactNativeWebView) {
      return;
    }
    const logSentry = (msg: Record<string, any>) => {
      if (msg.targetFunc !== 'getDeviceInfo') {
        return;
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          response: msg,
        });
        Sentry.captureMessage(`[Receive RN message] ${msg.targetFunc || msg.event || ''}`, 'debug');
      });
    };
    const handlerEvent = (e: Event) => {
      if ((e as MessageEvent).origin === 'https://js.stripe.com') {
        return;
      }
      if ((e as MessageEvent).origin === 'https://jiffygrocery.co.uk') {
        return;
      }
      if (!(e as MessageEvent).data) {
        return;
      }
      if ((e as MessageEvent).data[0] !== '{') {
        return;
      }
      try {
        const message: Record<string, any> = JSON.parse((e as MessageEvent).data || '{}');
        logSentry(message);
        if (message.event === 'showed') {
          mainStore.setIsRNReady(true);
        }
        if (message.event === 'menuChangeRouteEvent') {
          history.push(message.route);
        }
        if (message.event === 'appStateBackground') {
          mainStore.setInBackground(true);
        }
        if (message.event === 'appStateActive') {
          mainStore.setInBackground(false);
        }
        if (message.event === 'backButtonClicked') {
          history.length === 1 ? history.push('/') : history.goBack();
        }
        if (message.event === 'AppStateBackground') {
          mainStore.clearAllCache();
        }
        if (message.event === 'deeplinkGetted') {
          if (message.data?.deep_link_value) {
            const [type, value] = (message.data.deep_link_value as string).split('_');
            let pathname = '';
            let state: Record<string, string | number> = {};
            if (type === 'product') {
              pathname = `/product/${value}`;
            }
            if (type === 'category') {
              pathname = `/category/${value}`;
            }
            if (type === 'promo') {
              pathname = `/banner/${value}`;
            }
            if (type === 'cart') {
              pathname = '/cart';
            }
            if (type === 'favourites') {
              pathname = '/favorites';
            }
            if (type === 'purchased') {
              pathname = '/favorites';
              state = { activeTabNum: 1 };
            }
            if (type === 'search') {
              pathname = '/search';
              catalogStore.setSearchQuery(
                value.search(/%\D|%$/g) === -1 ? decodeURIComponent(value) : value,
              );
            }
            if (type === 'order-rate' && userStore.isAuthorized) {
              pathname = '/';
              mainStore.setDeferedRateOrder(true);
            }
            if (type === 'story') {
              storiesStore.requestStories().catch((error) => error && console.error(error));
              const storyByDeepLink = storiesStore.stories.find(
                (story) => story.deepLink === value);
              if (storyByDeepLink) pathname = `/stories/${storyByDeepLink.id}`;
            }
            if (pathname) {
              if (userStore.deliveryAddress) {
                history.push({
                  pathname: pathname,
                  state: { isDeeplink: true, ...state },
                });
              } else {
                mainStore.setDeferedDeeplink(pathname);
              }
            }
          }
        }
        if (mainStore.rnBridgeCallbacks[message.msgId]) {
          const callback = mainStore.rnBridgeCallbacks[message.msgId];
          if (message.isSuccessfull) {
            if (callback.onSuccess) {
              callback.onSuccess(message.args || {});
            }
          } else {
            if (callback.onError) {
              callback.onError(message.args || {});
            }
          }
          delete mainStore.rnBridgeCallbacks[message.msgId];
        }
      } catch (error) {
        error && console.error(error);
      }
    };
    document.addEventListener('message', handlerEvent);
    window.addEventListener('message', handlerEvent);
    mainStore.sendToRN('mount', {
      customerId: userStore.personalData.id || undefined,
    });
  }, [mainStore.isAllStoresSynchronized, isAvailable]);

  useLayoutEffect(() => {
    if (!window.ReactNativeWebView || !mainStore.isRNReady || !isAvailable) {
      return;
    }
    mainStore.sendToRN('getDeviceInfo', null, (data) => mainStore.initRN(data));
    //eslint-disable-next-line
  }, [mainStore.isRNReady, isAvailable]);

  useLayoutEffect(() => {
    if (!mainStore.isAllStoresSynchronized || !isAvailable) {
      return;
    }
    if (userStore.deliveryAddress) {
      orderStore.requestETA().catch((error) => error && console.error(error));
    }
    if (!mainStore.inBackground) {
      checkPushPermission();
    }
    const interval = setInterval(() => {
      if (!userStore.deliveryAddress || !orderStore.etaCalculation || mainStore.inBackground) {
        return;
      }
      orderStore.requestETA().catch((error) => error && console.error(error));
    }, REQUEST_ETA_INTERVAL);
    return () => clearInterval(interval);
  }, [
    mainStore.isAllStoresSynchronized,
    mainStore.inBackground,
    userStore.isFirstLaunch,
    isAvailable,
  ]);

  useEffect(() => {
    if (!mainStore.isAllStoresSynchronized || !isAvailable) {
      return;
    }
    const interval = setInterval(() => {
      if (mainStore.inBackground) {
        return;
      }
      mainStore.sendProductsShownToBI();
    }, ANALYTICS_PRODUCTS_SHOWN_INTERVAL);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [mainStore.isAllStoresSynchronized, mainStore.inBackground, isAvailable]);

  useEffect(() => {
    if (
      !checkoutStore.availableDeliveryMethods.length ||
      checkoutStore.availableDeliveryMethods.includes(ETADeliveryMethodType.JiffyDelivery)
    ) {
      checkoutStore.setDeliveryMethod(ETADeliveryMethodType.JiffyDelivery);
    } else {
      checkoutStore.setDeliveryMethod(checkoutStore.availableDeliveryMethods[0]);
    }
  }, [checkoutStore.availableDeliveryMethods]);

  useEffect(() => {
    const htmlTag = document.querySelector('html');
    if (htmlTag) {
      htmlTag.classList.add(`company-${company.name}`);
    }
  }, []);

  useEffect(() => {
    if (!search) {
      return;
    }
    const companyName = new URLSearchParams(search).get('company')?.toLowerCase();
    if (!companyName) {
      return;
    }
    if (companyName === 'none') {
      sessionStorage.removeItem('companyName');
    }
    if (company.isCompanyNameExist(companyName)) {
      sessionStorage.setItem('companyName', companyName);
    }
    window.history.back();
  }, [search]);

  useEffect(() => {
    if (!userStore.isAuthorized) {
      return;
    }
    orderStore.requestPriorityPaymentSystem();
  }, [userStore.isAuthorized]);

  if (!isAvailable) {
    return <PopoverGeneralError isShow={true} errorType="accessDenied" />;
  }

  if (!mainStore.isAllStoresSynchronized) {
    return <></>;
  }

  if (window.ReactNativeWebView && !mainStore.appVersion) {
    return <></>;
  }

  if (mainStore.isFuckupPopover) {
    return <PopoverFuckup />;
  }

  if (!company.isEnabled) {
    return (
      <div
        className="disabled-placeholder"
        style={{ backgroundColor: company.config.disabledPlaceholder?.backgroundColor }}
      >
        {company.config.disabledPlaceholder?.image ? (
          <img
            src={company.config.disabledPlaceholder.image}
            alt={company.config.disabledPlaceholder.alt}
          />
        ) : `${company.config.name} is disabled.`}
      </div>
    )
  }
  return (
    <div dir={!FLAG_HIDE_LANGS && userStore.language.includes('ar') ? 'rtl' : 'ltr'}>
      <Switch>
        <Route path="/delivery-address" component={DeliveryAddress} />
        <Route path="/inject" component={Inject} />
        {(userStore.isFirstLaunch || !userStore.deliveryAddress) && (
          <Redirect to="/delivery-address" />
        )}
        <Route component={PageLayout} />
      </Switch>
      <Alerts />
      <PopoverNeedChangeAddress />
      <PopoverGeneralError
        isShow={mainStore.isGeneralErrorPopover}
        onDismiss={() => mainStore.setIsGeneralErrorPopover(false)}
        errorType="general"
      />
      <PopoverGeneralError
        isShow={mainStore.isNoInternetPopover}
        onRetry={() => mainStore.retryInternetConnection()}
        errorType="internet"
      />
    </div>
  );
});
